import { html } from '../shared.js';
import ICONS from './icons.json'

const ICON_BASE_WIDTH = 30;
const iconNames = Object.keys(ICONS);

export { iconNames };

export class RehubIconWebComponent extends HTMLElement  {

  static get observedAttributes() {
    return ["name", "size", "rotate", "hflip", "vflip"];
  }

  constructor() {
    super();

    window.iconNames = Object.keys(ICONS);

    this.rotate = "0";
    this.hFlip = false;
    this.vFlip = false;
    this.name = "default";
    this.size = ICON_BASE_WIDTH;
    this.attachShadow({mode: "open"});
  }

  connectedCallback() {
    this.render();
  }

  render() {
    let width = `${this.size}px`;
    let height = `${this.size}px`;
    let rotate = `${this.rotate}deg`;
    let scaleX = this.hFlip ? -1 : 1;
    let scaleY = this.vFlip ? -1 : 1;

    this.shadowRoot.innerHTML = html`
      <style>
        :host{
          display: block;
          width: ${width};
          height: ${height};
          transform: rotate(${rotate}) scaleX(${scaleX}) scaleY(${scaleY});
        }
        svg{
          fill: currentColor;
        }
      </style>
      <svg width="${this.size}" height="${this.size}" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        ${this.getIcon(this.name)}
      </svg>
    `;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "hflip") this.hFlip = this.hasAttribute("hflip");
    else if (name === "vflip") this.vFlip = this.hasAttribute("vflip");
    else this[name] = newValue;

    this.render();
  }

  getIcon(name){
    let icon = ICONS[name];
    if(icon == null) icon = ICONS.shape_circle;

    return icon;
  }

}
