import { API } from "./api";

export type ChatMessageType = "PATIENT_DAILY";

export class ChatAPI extends API{

  static getChatGroups(memberId: string | null = null): Promise<any> {
    let url = `${this.getBaseUrl()}/message/chat/owner/groups`;
    if(memberId){
        url += "?memberId=" + memberId;
    }
    return this.get(url, {});
  }

  static getChatGroupMessages(groupId: string): Promise<any> {
    const url = `${this.getBaseUrl()}/message/chat/group/${groupId}/messages`;
    return this.get(url, {});
  }

  static postMessageToChatGroup(groupId: string, note: string, messageType: ChatMessageType | null = null, fileKey: string | null = null): Promise<any> {
    const url = `${this.getBaseUrl()}/message/chat/group/${groupId}/message/create`;
    let data: any = { note: note };

    if (messageType) data.messageType = messageType;
    if (fileKey) data.fileKey = fileKey;

    return this.post(url, data);
  }

  static postCallStatusToChatGroup(groupId: string, item: any): Promise<any> {
    const url = `${this.getBaseUrl()}/message/chat/group/${groupId}/message/create`;
    return this.post(url, item);
  }

  static postCallStatusToChatGroup2(groupId: string, item: any): Promise<any> {
    const url = `${this.getBaseUrl()}/message/chat/group/${groupId}/message/create2`;
    return this.postWithoutAuth(url, item);
  }

  static postMessageToAllGroups(note: string, messageType: ChatMessageType | null = null){
    this.getChatGroups().then((result: any) => {
      result.forEach((chat: any) => {
        this.postMessageToChatGroup(chat.groupId, note, messageType)
        .then((result: any) => {
          console.log(result);
        })
        .catch((error: any) => {
          console.error("ERROR TO POST MESSAGE", error);
        });
      });
    });
  }

  static downloadReport(diagnosisId: any, language: string, onlyData: boolean = false): Promise<any> {
    const url = `${this.getBaseUrl()}/message/report/download/PATIENT_DIAGNOSIS_SUMMARY`;
    let body: any = {};

    if (language == null) {
      body = {
        diagnosisId: diagnosisId,
      };
    } else {
      body = {
        lang: language,
        diagnosisId: diagnosisId,
      };
    }
    if (onlyData) body.onlyData = onlyData;

    return this.post(url, body);
  }

  static CRILreportUpload(reportName: string, diagnosisId: string, therapyExternalId: string ): Promise<any>{
    const url = `${this.getBaseUrl()}/message/report/upload/${reportName}?therapyExternalId=${therapyExternalId}`;
    return this.post(url, {'diagnosisId':diagnosisId});
  }

  static getJoinMeetChime(title: string, groupId: string): Promise<any> {
    let url = `${this.getBaseUrl()}/message/chime/meeting/join`;
    let body = {
      title: title,
      groupId: groupId
    }

    return this.post(url, body);
  }

  static getJoinMeetChime2(ownerId: string, title: string, groupId: string): Promise<any> {
    let url = `${this.getBaseUrl()}/message/chime/meeting/join2`;
    let body = {
      ownerId: ownerId,
      title: title,
      groupId: groupId
    }

    return this.postWithoutAuth(url, body);
  }

  static getChimeStatus(): Promise<any> {
    return this.getChimeInfoByType("status");
  }

  static getChimeList(params: any = null): Promise<any> {
    return this.getChimeInfoByType("list", params);
  }

  private static getChimeInfoByType(type: string, params: any = {}): Promise<any> {
    let url = `${this.getBaseUrl()}/message/chime/${type}`;
    return this.get(url, params);
  }

  static postVoiceControlCommand(body: any): Promise<any> {
    const url = `${this.getBaseUrl()}/message/voice`;
    return this.post(url, body);
  }
}