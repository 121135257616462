import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CredentialsService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class SelfServiceGuard implements CanActivate {

  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isSelfService = this.credentialsService.isSelfService();
    if(!isSelfService){
      return true;
    }
   if (isSelfService && !route.queryParams.therapyId) {
      this.router.navigate(['/self-service2'], { replaceUrl: true });
      return false;
    }

    return true;
  }
}