import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { EventBusService, LocalSettingsService } from '@app/@core/service';
import { environment } from '@env/environment';
import { I18nService } from '@app/i18n';

import { DeployInfoService } from '@app/@core/service/deploy-info.service';
import { SharedUtils } from '@rehub-shared/utils/utils';
import { CoachService } from '@app/@core/service/coach.service';
import { CredentialsService } from '@app/@core/auth';
import { Permission } from '@rehub-shared/utils/permissions';


@Component({
  selector: 'rehub-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.scss'],
  host: {class: "cy-coach"}
})
export class CoachComponent implements OnInit, OnDestroy {

  SharedUtils = SharedUtils;

  @ViewChild('coach') coach: any;

  animation: string = "idle";

  coachEnableEventBus: any;
  coachDisableEventBus: any;
  languageChangedEventBus: any;
  isCoachDisabled: boolean = false;
  isWellwo: boolean = false;
  isCoachHidden: boolean = false;
  isSelfService: boolean = false;
  text: string;

  constructor(
    public coachService: CoachService,
    private i18nService: I18nService,
    private eventbus: EventBusService,
    private deployInfoService: DeployInfoService,
    private credentialsService: CredentialsService,
    private localSettingsService: LocalSettingsService
  ) { }

  ngOnDestroy() {
    this.coachEnableEventBus.unsubscribe();
    this.coachDisableEventBus.unsubscribe();
    this.languageChangedEventBus.unsubscribe();
  }

  ngOnInit() {
    this.isWellwo = this.credentialsService.checkPermission(Permission.SS_config_wellwo);
    this.isCoachHidden = this.credentialsService.checkPermission(Permission.Coach_Patient_D);
    this.isSelfService  = this.credentialsService.isSelfService();
    if (this.isCoachHidden) this.setDisabled(true);


    let settings: any = this.localSettingsService.get();
    this.isCoachDisabled = settings.coachDisabled == true;

    this.coachEnableEventBus = this.eventbus.subscribe("COACH_ENABLE", (data: any)=>{
      this.setDisabled(false);
    });

    this.coachDisableEventBus = this.eventbus.subscribe("COACH_DISABLE", (data: any)=>{
      this.setDisabled(true);
    });

    this.languageChangedEventBus = this.eventbus.subscribe("LANGUAGE_CHANGED", (data: any)=>{
      this.coachService.resetState();
      this.initCoach();
    });

    this.coachService.onShowMessage((data: any) =>{
      this.show(data);
    });

    this.initCoach();
  }

  initCoach() {
    let settings: any = this.localSettingsService.get();

    let languageCode = this.i18nService.getLanguageCode();
    let version = environment.media_version;

    const params: any = {
      audioAssetsBaseUrl: `${environment.web_resources_url}/editor-assets/${version}/coach/COACH_AUDIO/`,
      languageCode: languageCode,
      cacheUrlParam: this.deployInfoService.getURLCacheParam(),
      disabled: settings.coachDisabled,
    }

    this.coachService.init(params);
  }

  setDisabled(disabled: boolean){
    let settings: any = this.localSettingsService.get();

    if(disabled){
      //this.stopCurrentMessage();
      this.animation = "idle";
      this.text = "";
      settings.coachDisabled = disabled;
      this.coachService.disable();
    }else{
      delete settings.coachDisabled;
      this.coachService.enable();
    }

    this.localSettingsService.set(settings);
  }
  toggleCoach() {
    let settings: any = this.localSettingsService.get();
    this.isCoachDisabled = !this.isCoachDisabled;
    if (this.isCoachDisabled) this.coachService.disable();
    else {
      delete settings.coachDisabled;
    this.coachService.enable();}
  }

  show(message: any){
    if(!this.coach) return;

    let translateParams = {name: this.coachService.getState().name, joint: this.coachService.getState().joint};
    let translated = message.text ? this.i18nService.translate(message.text, translateParams) : "";

    this.coach.nativeElement.setText(translated);
    this.coach.nativeElement.setAnimation(message.animation);
  }
}
