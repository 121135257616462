import { Component, OnInit, Input } from '@angular/core';
import { LocalSettingsService, ModalService } from '@app/@core/service';
import { I18nService } from '@app/i18n';
import { Router } from "@angular/router";
import { SharedUtils } from '@rehub-shared/utils/utils';
import { AuthenticationService } from '@app/@core/auth';
@Component({
  selector: 'rehub-start-selfservice-modal',
  templateUrl: './start-selfservice-modal.component.html',
  styleUrls: ['./start-selfservice-modal.component.scss'],
  host: {class : 'start-selfservice-modal'}
})
export class StartSelfserviceModalComponent implements OnInit {
// Recibe la data enviada desde el servicio
  lang: string;
  url: string;
  time: number;
  protocolsinfo: any[];
  isMobile: boolean = false;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private router: Router,
    private localSettingsService: LocalSettingsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.lang = this.i18nService.getLanguage();
    this.isMobile = SharedUtils.isMobile();

  }

  close(): void {
    this.modalService.close();
    this.authenticationService.logout();
  }

  closeForver(): void {
    let settings: any = this.localSettingsService.get();
    settings.selfServiceModal = true;
    this.localSettingsService.set(settings);
    this.modalService.callback(true);
    this.modalService.close(false, false);
    this.router.navigate(['/self-service2']);
  }


}
