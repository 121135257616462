import { html } from '../shared.js';

const ICON_BASE_WIDTH = 30;

export class RehubSpinnerHariWebComponent extends HTMLElement  {

  static get observedAttributes() {
    return ["size", "center"];
  }

  constructor() {
    super();
    this.center = false;
    this.size = 1;
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
  }

  render() {
    let width = ICON_BASE_WIDTH * this.size;
    let height = ICON_BASE_WIDTH * this.size;

    this.shadowRoot.innerHTML = html`
      <style>
        :host{
          display: block;
          width: ${width}px;
          height: ${height}px;
        }

        img{
          fill: currentColor;
          stroke: currentColor;
          width: ${width}px;
          height: ${height}px;
          animation: spin 1.5s  alternate-reverse infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes stroke {
          0% { stroke-dashoffset: 100; }
          100% { stroke-dashoffset: 60; }
        }
      </style>
      <img src="https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif" />
    `;

    if(this.center){
      let hostElement = this.shadowRoot.getRootNode().host;

      hostElement.style.position = "absolute";
      hostElement.style.left = `calc(50% - ${width / 2}px)`;
      hostElement.style.top = `calc(50% - ${height / 2}px)`;
    }

  }

  attributeChangedCallback(name, oldValue, newValue) {
    if(name == "center") this.center = newValue == "true" || newValue == "";
    else this[name] = newValue;
    this.render();
  }

}
