import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '@app/@core/service';
import { I18nService } from '@app/i18n';
import { Router } from "@angular/router";
import { SharedUtils } from '@rehub-shared/utils/utils';

@Component({
  selector: 'rehub-exercise-finish-selfservice-modal',
  templateUrl: './exercise-finish-selfservice-modal.component.html',
  styleUrls: ['./exercise-finish-selfservice-modal.component.scss'],
  host: {class : 'finish-exercise-modal'}
})
export class ExerciseFinishedSelfserviceModalComponent implements OnInit {
  @Input() data: any; // Recibe la data enviada desde el servicio
  lang: string;
  url: string;
  time: number;
  protocolsinfo: any[];
  isMobile: boolean = false;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private router: Router
  ) { }

  ngOnInit() {
    this.lang = this.i18nService.getLanguage();
    this.isMobile = SharedUtils.isMobile();
    this.url = `https://web-resources.dycare-services.com/rehub-frontend/png/clap.png`;

    // Formatear el tiempo con sufijo m o s
    if (this.data && this.data.time) {
        const seconds = Math.floor(this.data.time); // quitamos decimales
        if (seconds >= 60) {
            // Si son 60 segundos o más, mostrar en minutos
            this.data.time = `${Math.floor(seconds/60)}m`;
        } else {
            // Si son menos de 60 segundos
            this.data.time = `${seconds}s`;
        }
    }
  }

  onClose(): void {
    this.modalService.close(false, false);
    this.router.navigate(['/self-service2']);
  }

  visitProgress() {
    this.modalService.close(false, false);
    this.router.navigate(["/evolution"]);
  }

  get formattedTime(): string {
    return this.data?.time ? Number(this.data.time).toFixed(2) : '0.00';
  }
}
