<header class="fixed-top rehub-nav-header rehub-header" *ngIf="!isWellwo" [ngClass]="{'shadow': !isSelfService(), 'no-selfservice-background': !isSelfService()}">

  <nav class="d-flex flex-row align-items-center h-100 navbar navbar-dark navbar-expand-xl p-0 " [ngClass]="{'px-3': !isMobile, 'px-2': isMobile}">
    <a class="navbar-brand text-primary d-flex flex-row align-items-center justify-content-center" (click)="hideMenu()"
      [routerLink]="isSelfService() ? '/self-service2' : '/'" style="gap: 5px; margin-left: 3%;">

      <ng-container *ngIf="!isCustomLogo">
        <ng-container *ngIf="!isSelfService()">
          <img _ngcontent-c0="" alt="Rehub" src="assets/images/rehub_logo_icon.png" style=" height: 30px;">
          <!-- <rehub-icon name="rehub" size="25"></rehub-icon>
          <h2 class="m-0 color-black">ReHub</h2> -->
        </ng-container>
        <ng-container *ngIf="isSelfService()">
          <h2 class="m-0">VitalHub</h2>
        </ng-container>
      </ng-container>

      <img *ngIf="isCustomLogo" [src]="customLogo" class="rehub-custom-icon">
    </a>


    <div class="d-flex flex-row align-items-center" [ngStyle]="{'order': isMobile ? 1 : 'inherit'}">
      <div *ngIf="!isCoachHidden && !isSelfServices" class="d-xl-none nav-item d-flex flex-column justify-content-center mr-2">
        <img
          (click)="toggleCoach()"
          class="pointer"
          style="height: 40px;"
          [style.opacity]="isCoachDisabled ? '0.3' : '1.0'"
          data-toggle="tooltip"
          data-placement="bottom"
          [title]="(isSelfServices ? 'general_toggle_hari' : 'general_toggle_coach') | translate"
          [src]="isSelfServices ? 'https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif' : '/assets/images/coach/coach_head_small.png'">
      </div>
      <div class="d-xl-none nav-item d-flex flex-column justify-content-center mr-2" *ngIf="measurementProgramService.isEnabled()">
        <a class="cursor-pointer" data-placement="bottom" [ngClass]="getRealtimeStatusColor()" data-toggle="tooltip"
          data-placement="top" title="{{'sensor_settings_title_state_' + getRealtimeStatusColor() | translate}}">
          <rehub-icon name="connection" size="40"></rehub-icon>
        </a>
      </div>

      <div class="d-flex align-items-center pr-2">
        <!---
      <img *ngIf="isMobile" (click)="toggleCoach()" class="pointer" style="height: 60px;"
        [style.opacity]="isCoachDisabled ? '0.3' : '1.0'" data-toggle="tooltip" data-placement="bottom"
        [title]="'general_toggle_hari' | translate"
        src="https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif">
        -->
        <img *ngIf="isMobile && isSelfServices"
        (click)="toggleCoach()"
        class="pointer"
        [ngStyle]="{'height': isSelfServices ? '60px' : '40px'}"
        [style.opacity]="isCoachDisabled ? '0.3' : '1.0'"
        data-toggle="tooltip"
        data-placement="bottom"
        [title]="(isSelfServices ? 'general_toggle_hari' : 'general_toggle_coach') | translate"
        [src]="isSelfServices ? 'https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif' : '/assets/images/coach/coach_head_small.png'">

      <rehub-icon name="menu" class="d-xl-none text-primary cursor-pointer"
        (click)="toggleMenu($event)"
        *ngIf="!(isSelfServices && isAssegur) && !isMenuDisabled">
      </rehub-icon>
    </div>
    </div>

    <!-- Desktop -->
    <div class="p-2 d-none d-xl-block w-100">
      <div class="d-flex flex-row align-items-center" style="gap: 1rem;"    [ngClass]="{'justify-content-end': isSelfService(), 'justify-content-start': !isSelfService()}">

        <ng-container *ngFor="let menuItem of menuItems">
          <ng-container *ngIf="!menuItem.separator && !menuItem.options.hidden && menuItem.show">

            <a *ngIf="isSelfService() && !menuItem.options.onlyMobile"
            [routerLink]="menuItem.route"
            routerLinkActive="header-active"
            [routerLinkActiveOptions]="menuItem.options"
            class="d-flex align-items-center cursor-pointer text-decoration-none position-relative nav-link-btn">
           <span>{{menuItem.key | translate}}</span>
           <rehub-icon name="shape_circle" size="10" class="new-notication" *ngIf="hasNewMessage && menuItem.key == 'navigation_chat'"></rehub-icon>
         </a>

         <!-- Si no es self-service, mostrar un diseño diferente -->
         <a *ngIf="!isSelfService() && !menuItem.options.onlyMobile"
            [routerLink]="menuItem.route"
            style="opacity: 0.5;"
            class="d-flex ml-1 align-items-center cursor-pointer text-uppercase text-primary font-weight-bold text-decoration-none position-relative">
           <rehub-icon [attr.name]="menuItem.icon" size="20"></rehub-icon>
           <span class="ml-2">{{menuItem.key | translate}}</span>
           <rehub-icon name="shape_circle" size="10" class="new-notication" *ngIf="hasNewMessage && menuItem.key == 'navigation_chat'"></rehub-icon>
         </a>


          </ng-container>
        </ng-container>

      </div>
    </div>


    <!-- Mobile -->

    <div class=" text-primary rehub-nav p-2 d-none d-block d-xl-none bg-white card-rounded mx-2 shadow-lg" (clickOutside)="hideMenu()" *ngIf="!menuHidden && !isMenuDisabled">

      <div class="d-flex flex-column list-unstyled m-0" style="font-size: 12px;">

        <ng-container *ngFor="let menuItem of menuItems">
          <ng-container *ngIf="!menuItem.options.hidden && !menuItem.onlyDesktop && menuItem.show">
            <a (click)="hideMenu()" class="dropdown-item d-flex text-primary active-item align-items-center" [routerLink]="menuItem.route" routerLinkActive="header-active" [routerLinkActiveOptions]="menuItem.options">
              <rehub-icon [attr.name]="menuItem.icon"size="25" class="mr-2"></rehub-icon>
              {{menuItem.options.onlyDesktop}}
              {{menuItem.title | translate}}
            </a>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="featureFlagsService.showLogoutPatient || !isAssegur">
          <hr class="bg-white bg-white w-100 my-2 d-xl-none" style="opacity: 0.5;">
          <a class="dropdown-item d-flex text-primary" (click)="logout()" *ngIf="!isAssegur">
            <rehub-icon name="logout_1" size="20" class="mr-2 "></rehub-icon>
            {{'general_logout' | translate}}
          </a>
        </ng-container>

      </div>

    </div>

   <div class="nav-item flex-column justify-content-center d-xl-block ml-auto"
   *ngIf="(!isSelfServices && !isMobile && !isCoachHidden)">
      <img
        (click)="toggleCoach()"
        class="pointer"
        [ngStyle]="{'height': isSelfServices ? '60px' : '40px'}"
        [style.opacity]="isCoachDisabled ? '0.3' : '1.0'"
        data-toggle="tooltip"
        data-placement="bottom"
        [title]="(isSelfServices ? 'general_toggle_hari' : 'general_toggle_coach') | translate"
        [src]="isSelfServices ? 'https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif' : '/assets/images/coach/coach_head_small.png'">
    </div>
 <!--
    <div class="nav-item d-flex flex-column justify-content-center mr-2" *ngIf="measurementProgramService.isEnabled()">
      <a class="cursor-pointer" data-placement="bottom" [ngClass]="getRealtimeStatusColor()" data-toggle="tooltip"
        data-placement="top" title="{{'sensor_settings_title_state_' + getRealtimeStatusColor() | translate}}">
        <rehub-icon name="connection" size="40"></rehub-icon>
      </a>
    </div> -->


    <a *ngIf="voiceCredentials" class="cursor-pointer" (click)="toggleMicro()" style="margin-right:5px" >
      <rehub-icon name="micro" size="40" *ngIf="microState == 1" style="color:yellowgreen"></rehub-icon>
      <rehub-icon name="lightbulb" size="40" style="color:blue" *ngIf="microState == 2"></rehub-icon>
      <rehub-icon name="micro_off" size="40" *ngIf="microState == 0" style="color: #80c4cc"></rehub-icon>
    </a>


    <div class="d-none d-xl-block ml-2" *ngIf="!isSelfService()">
      <ul class="navbar-nav mr-auto align-items-center">
        <div class="d-none d-xl-flex dropdown align-items-center" style="min-width: 200px; cursor: pointer;">
          <ng-container>
            <div class="d-none d-xl-flex dropdown align-items-center" data-toggle="dropdown">
              <rehub-avatar [image]="profileSettings.image" [name]="profileSettings.alias"></rehub-avatar>
              <div class="d-flex justify-content-center ml-2 text-primary">
                <h4 class="m-0">{{profileSettings.name}}</h4>
                <rehub-icon name="back_to" size="20" rotate="270" class="mr-2 mr-2 mt-1" *ngIf="!isInFullScreen()"></rehub-icon>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="dropDownRC"></ng-container>
          </ng-container>
        </div>
      </ul>
    </div>

    <!-- Segundo bloque para cuando es self service y no es assegur -->
    <div class="ml-2" *ngIf="isSelfServices" style="margin-right: 4%">
      <ul class="navbar-nav mr-auto align-items-center">
        <div *ngIf="!isMobile" class="dropdown profile-button d-flex align-items-center cursor-pointer nav-link-btn text-decoration-none position-relative" (click)="onAvatarClick()" data-toggle="dropdown">
          <ng-container>
            <div class="profile-button-text d-flex justify-content-center text-primary">
              <h4 class="m-0">{{ 'my_profile' | translate }}</h4>
            </div>
          </ng-container>
          <ng-container *ngTemplateOutlet="dropDownRCA"></ng-container>
        </div>
        <div *ngIf="!isCoachHidden && !isMobile" class="d-flex align-items-center">
          <img (click)="toggleCoach()" class="pointer" style="height: 60px;" [style.opacity]="isCoachDisabled ? '0.3' : '1.0'" data-toggle="tooltip" data-placement="bottom" [title]="'general_toggle_hari' | translate" src="https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif">
        </div>
      </ul>
    </div>


  </nav>
</header>

<ng-template #dropDownRCA >
  <div class="dropdown-menu bg-white" *ngIf="!isMenuDisabled">
    <a class="dropdown-item d-flex" (click)="onProfileClick()"  *ngIf="!isAssegur">
      <rehub-icon name="settings" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{ 'navigation_profile_config' | translate }}</a>
    <a class="dropdown-item d-flex" (click)="onHelpPressed()">
      <rehub-icon name="helper" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'help_header' | translate}}
    </a>
    <a class=" dropdown-item cursor-pointer full-screen-chk d-flex" data-toggle="tooltip"
      data-placement="bottom" (click)="toggleFullScreen()" *ngIf="!isAssegur">
      <rehub-icon name="fullscreen_disable" size="20"  class="mr-2 mr-2  mt-1" *ngIf="isInFullScreen()"></rehub-icon>
      <rehub-icon name="fullscreen_enable" size="20"  class="mr-2 mr-2  mt-1" *ngIf="!isInFullScreen()"></rehub-icon>
      {{'profile_toggle_full_screen' | translate}}
    </a>
    <a class="dropdown-item d-flex" (click)="logout()" *ngIf="!isAssegur">
      <rehub-icon name="logout_1" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'general_logout' | translate}}
    </a>

  </div>
</ng-template>

<ng-template #dropDownRC >
  <div class="dropdown-menu bg-white" *ngIf="!isMenuDisabled">
    <a class="dropdown-item d-flex text-primary" (click)="onProfileClick()" *ngIf="!isAssegur">
      <rehub-icon name="settings" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'navigation_profile_config' | translate}}</a>
    <a class="dropdown-item d-flex text-primary" (click)="onHelpPressed()">
      <rehub-icon name="helper" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'help_header' | translate}}
    </a>
    <a class="text-primary dropdown-item cursor-pointer full-screen-chk d-flex" data-toggle="tooltip"
      data-placement="bottom" (click)="toggleFullScreen()" *ngIf="!isAssegur">
      <rehub-icon name="fullscreen_disable" size="20"  class="mr-2 mr-2  mt-1" *ngIf="isInFullScreen()"></rehub-icon>
      <rehub-icon name="fullscreen_enable" size="20"  class="mr-2 mr-2  mt-1" *ngIf="!isInFullScreen()"></rehub-icon>
      {{'profile_toggle_full_screen' | translate}}
    </a>
    <a class="dropdown-item d-flex text-primary" (click)="logout()" *ngIf="!isAssegur">
      <rehub-icon name="logout_1" size="20" class="mr-2 mr-2  mt-1"></rehub-icon>
      {{'general_logout' | translate}}
    </a>

  </div>
</ng-template>

<div *ngIf="loadingChromecast">
  <div class="background"></div>

  <div class="align-items-center content d-flex flex-column h-100 justify-content-center m-auto">
    <rehub-spinner size="7"></rehub-spinner>
    <h1 class="text-white">{{'general_connecting' | translate}}</h1>
  </div>
</div>
