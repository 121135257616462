import { html, baseURL } from '../shared.js';
import spine from './spine-canvas.js';

export class RehubCoachHariWebComponent extends HTMLElement {

  static get observedAttributes() {
    return [];
  }

  constructor() {
    super();
    this.canvas = null;

    this.ctx = null;
    this.delta = null;
    this.lastFrameTime = 0;
    this.skeletonRenderer = null;
    this.assetManager = null;
    this.atlasLoader = null;
    this.skeleton = null;
    this.state = null;
    this.bounds = null;
    this.atlas = null;
    this.animationState = null;
    this.animationName = "idle";
    this.message = "";

    this.animated = false;
    this.hidden = false;

    this.firstTimeRendering = true;
    this.static = false;
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.render();
    // this.init();
  }

  render() {
    //TODO: cargar fuente correctamente
    let innerHtml =`
      <style>
        :host{
          display: block;
          position: fixed;
          z-index: 9999;
          bottom: 0;
          right: 0;

        }

        .img-static{
          margin-right: 10px;
        }

        .message{
          font-family: Roboto !important;
          z-index: 1001;
          width: 300px;
          min-height: 50px;
          background-color: #fff;
          position: absolute;
          right: 20px;
          bottom: 250px;
          border-radius: 17px;
          padding: 10px 10px;
          border: 1px solid #ccc;
        }

        .message:after{
          z-index: -1;
          border-left: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          display: block;
          width: 20px;
          height: 20px;
          content: "";
          background-color: #fff;
          transform: rotate(-45deg);
          position: absolute;
          bottom: -12px;
          right: 60px;
        }

        .hide{
          display: none;
        }
      </style>

      `;
    if (this.message != "") {
      innerHtml += `
      <div id="message" class="message">
        ${this.message}
      </div>
      `;
    }
    if (this.animationName == "idle") {
      innerHtml += `

      <img class="img-static"
        src="https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif" alt="coach" width="150px"
        height="auto" />`
    }

    else {
      innerHtml += `
      <img class="img-static"
        src="https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-talk.gif" alt="coach" width="150px"
        height="auto" />`

    }

    this.shadowRoot.innerHTML = innerHtml;
    console.log("render");
  }

  setStatic(value){
    this.static = value;
  }

  setText(text){
    this.message = text;
    // if(!text || text == ""){
    //   message.classList.add("hide");
    //   message.innerText = "";
    // }else{
    //   message.classList.remove("hide");
    //   message.innerText = text;
    // }
  }

  init(){
    this.canvas = this.shadowRoot.getElementById("canvas");
    if(!this.canvas) return;

    this.lastFrameTime = Date.now() / 1000;
    this.ctx = this.canvas.getContext("2d");

    this.skeletonRenderer = new spine.canvas.SkeletonRenderer(this.ctx);
    // enable debug rendering
    this.skeletonRenderer.debugRendering = false;
    // enable the triangle renderer, supports meshes, but may produce artifacts in some browsers
    this.skeletonRenderer.triangleRendering = false;

    this.assetManager = new spine.canvas.AssetManager();

    this.load("skeleton");

    requestAnimationFrame(this.loadAssets.bind(this));
  }

  load(name){
    this.assetManager.loadText(baseURL + "coach/" + name + ".json");
    this.assetManager.loadText(baseURL + "coach/" + name + ".atlas");
    this.assetManager.loadTexture(baseURL + "coach/" + name + ".png");
  }

  loadAssets(){
    if (this.assetManager.isLoadingComplete()) {
      //var data: any = this.loadSkeleton("spineboy", "run");
      var data = this.loadSkeleton("skeleton", "idle", "default");
      this.skeleton = data.skeleton;
      this.state = data.state;
      this.bounds = data.bounds;
      this.resize();
      requestAnimationFrame(this.drawCanvas.bind(this));
    } else {
      requestAnimationFrame(this.loadAssets.bind(this));
    }
  }

  drawCanvas(){
    var now = Date.now() / 1000;
    var delta = now - this.lastFrameTime;
    this.lastFrameTime = now;

    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.fillStyle = "#cccccc";
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();

    this.state.update(delta);
    this.state.apply(this.skeleton);
    this.skeleton.updateWorldTransform();
    this.skeletonRenderer.draw(this.skeleton);

    // this.ctx.strokeStyle = "green";
    // this.ctx.beginPath();
    // this.ctx.moveTo(-1000, 0);
    // this.ctx.lineTo(1000, 0);
    // this.ctx.moveTo(0, -1000);
    // this.ctx.lineTo(0, 1000);
    // this.ctx.stroke();

    requestAnimationFrame(this.drawCanvas.bind(this));
  }

  resize(){
    var w = 200;
    var h = 350;

    //console.log(w, h);

    if (this.canvas.width != w || this.canvas.height != h) {
      this.canvas.width = w;
      this.canvas.height = h;
    }

    // magic
    var centerX = this.bounds.offset.x + this.bounds.size.x / 2;
    var centerY = this.bounds.offset.y + this.bounds.size.y / 2;
    var scaleX = this.bounds.size.x / this.canvas.width;
    var scaleY = this.bounds.size.y / this.canvas.height;
    var scale = Math.max(scaleX, scaleY) * 1.2;
    if (scale < 1) scale = 1;
    var width = this.canvas.width;
    var height = this.canvas.height;

    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.translate(width / 2, height - (height / 2.7));
    this.ctx.scale(1 / scale, -(1 / scale));

  }

  loadSkeleton(name, initialAnimation, skin){

    // Load the texture atlas using name.atlas and name.png from the AssetManager.
    // The function passed to TextureAtlas is used to resolve relative paths.
    this.atlas = new spine.TextureAtlas(this.assetManager.get(baseURL + "coach/" + name + ".atlas"), (path) => {
      return this.assetManager.get(baseURL + "coach/" + path);
    });

    // Create a AtlasAttachmentLoader, which is specific to the WebGL backend.
    this.atlasLoader = new spine.AtlasAttachmentLoader(this.atlas);

    // Create a SkeletonJson instance for parsing the .json file.
    var skeletonJson = new spine.SkeletonJson(this.atlasLoader);

    // Set the scale to apply during parsing, parse the file, and create a new skeleton.
    var skeletonData = skeletonJson.readSkeletonData(this.assetManager.get(baseURL + "coach/" + name + ".json"));

    //console.log(skeletonData)
    var skeleton = new spine.Skeleton(skeletonData);
    skeleton.flipY = true;
    var bounds = this.calculateBounds(skeleton);
    skeleton.setSkinByName(skin);

    // Create an AnimationState, and set the initial animation in looping mode.
    let animationStateData = new spine.AnimationStateData(skeleton.data);
    animationStateData.defaultMix = 0.2;
    this.animationState = new spine.AnimationState(animationStateData);
    this.setAnimation(initialAnimation);

    // Pack everything up and return to caller.
    return { skeleton: skeleton, state: this.animationState, bounds: bounds };
  }

  calculateBounds(skeleton) {
    var data = skeleton.data;
    skeleton.setToSetupPose();
    skeleton.updateWorldTransform();
    var offset = new spine.Vector2();
    var size = new spine.Vector2();
    skeleton.getBounds(offset, size, []);
    return { offset: offset, size: size };
  }

  setAnimation(animation, loop){
    this.animationName = animation;
    console.log("animation", animation);
    this.render();
    // if(this.animationState) this.animationState.setAnimation(0, animation, true);
  }

}