import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DOMService } from '@app/@core/service/dom.service';
import { ModalService} from '@app/@core/service/modal.service';
import { SharedUtils } from '@rehub-shared/utils/utils';

@Component({
  selector: 'rehub-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit {

  @Input() data: any;
  showCircle: boolean = false;
  SharedUtils = SharedUtils;

  @ViewChild('content', {
    read: ViewContainerRef
  }) viewContainerRef: ViewContainerRef;

  constructor(
    private modalService: ModalService,
    private domService: DOMService
  ) {}

  ngAfterViewInit() {
    this.showCircle = this.data && this.data.showCircle; // TODO cuando esten todos los modales pasados quitarlo
    this.domService.addToParent(this.viewContainerRef, this.modalService.childComponent, this.data);
  }
}