import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/@core/service';
import { I18nService } from '@app/i18n';
import { Router } from "@angular/router";

@Component({
  selector: 'rehub-exercise-finish-modal',
  templateUrl: './exercise-finish-modal.component.html',
  styleUrls: ['./exercise-finish-modal.component.scss']
})
export class ExerciseFinishedModalComponent implements OnInit {
  lang: string;
  url: string;

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.lang = this.i18nService.getLanguage();
    this.url = `https://web-resources.dycare-services.com/rehub-frontend/gif/exerciseFinish.gif`;
  }

  onClose(): void {
    this.modalService.close(false, false);
  }

  visitProgress() {
    this.modalService.close(false, false);
    this.router.navigate(["/evolution"]);
  }

}