import { html } from '../shared.js';
import CalendarUtils from './calendar-utils.js';

export class RehubCalendarWebComponent extends HTMLElement  {

  constructor() {
    super();
    this._events = {};
    this.selectedMonth = 0;
    this.selectedDay = null;
    this.rendered = false;
    this.attachShadow({ mode: "open" });
  }

  capitalize(text){
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  upperCase(text){
    return text.toUpperCase();
  }

  onNextMonthPressed(){
    this.selectedMonth++;
    this.onMonthChanged();
    this.renderDays();
  }

  onPreviousMonthPressed(){
    this.selectedMonth--;
    this.onMonthChanged();
    this.renderDays();
  }

  onDayPressed(day){
    if(this.selectedDay == day) return;

    this.selectedDay = day;

    this.dispatchEvent(new CustomEvent("daySelected", {
      composed: true,
      detail: {
        day: day
      }
    }));

    let dayElements = this.shadowRoot.querySelectorAll(".day");

    dayElements.forEach((dayElement)=>{
      this.renderSelectedDay(dayElement);
    });
  }

  onMonthChanged(){
    let currentMonth = this.today.clone().add(this.selectedMonth, 'month');

    this.dispatchEvent(new CustomEvent("monthSelected", {
      composed: true,
      detail: {
        day: currentMonth.format('YYYY-MM')
      }
    }));
  }
  set events(value){
    this._events = value;
    let dayElements = this.shadowRoot.querySelectorAll(".day");
    dayElements.forEach((dayElement)=>{
      this.renderEvents(dayElement);
    });
}


  get events(){
    return this._events;
  }

  set moment(value){
    this._moment = value;
    this.render();
    if(this._selected) this.initSelectedDay();
  }

  get moment(){
    return this._moment;
  }

  set selected(value){
    this._selected = value;
    if(this.moment) this.initSelectedDay();
  }

  get selected(){
    return this.selectedDay;
  }

  initSelectedDay(){
    if(!this.today) this.today = CalendarUtils.getToday(this.moment);
    this.selectedDay = this.moment(this._selected).startOf("day");
    this.selectedMonth = CalendarUtils.getMonthDiff(this.selectedDay, this.today);
    this.renderDays();
  }

  getDayEventColor(day){
    let event = Object.keys(this._events).find((eventDay)=>{
      return CalendarUtils.isSameDay(day, this.moment(eventDay));
    });
    return event ? this._events[event].color : "transparent";
  }
  render() {
    this.today = CalendarUtils.getToday(this.moment);

    if (!this.selectedDay) {
      this.selectedDay = CalendarUtils.getToday(this.moment).toISOString();
    }

    this.selectedDay = this.moment(this.selectedDay).startOf("day");

    this.shadowRoot.innerHTML = html`
      <style>
        :host{
          display: block;
          user-select: none;
          color: #828282;
        }

        .next,.previous{
          cursor: pointer;
        }

        .header{
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: fit-content;
          color: #212529;
          padding-left: 5px;
          padding-right: 5px;
          gap:10px;
          margin-bottom: 3%;
        }

        .header h1{
          font-size: 1.5em;
          font-weight: bold;
        }
        .flechas{
          display: flex;
          flex-direction: row;
          gap: 20px;
          align-items: center;
          color: var(--color-primary);
        }
        .dayNames {
          height: fit-content;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: left;
          width: 99.89%;
          border: 1px solid #E8E8E8;
          border-radius: 30px 30px 0 0;
        }

        .dayName {
          text-align: left;
          width: calc(100% / 7); /* Asegura que todos los nombres de los días tengan el mismo ancho */
          margin-left: 20px;
          font-weight: bold;
          height: 100%;
          border-right: 1px solid #E8E8E8;
        }

        /* El último .dayName no tendrá border-right */
        .dayNames .dayName:last-child {
          border-right: none;
        }

        .content {
          height: 75%;
          width: 100%;
          display: inline-grid;
          grid-template-columns: repeat(7, auto);
          grid-auto-rows: 1fr;
          border-left: 1px solid #E8E8E8;
          min-height: 300px; /* Ajusta según sea necesario */
          margin-bottom: 20px;
        }

        .day{
          display: flex;
          font-weight: bold;
          flex-direction: column;
          // align-items: center;
          text-align: left;
          cursor: pointer;
          border-bottom: 1px solid #E8E8E8;
          border-right: 1px solid #E8E8E8;
          padding: 10px;
          height: 120px;
          justify-content: space-between;
        }

        .day > span {
          width: 20px;
          height: 20px;
          padding-top: 3px;
          padding-bottom: 7px;
          padding-left: 5px;
          padding-right: 5px;
          border-radius: 20px;
          text-align: center;
        }

        .day > rehub-icon {
          display: flex;
          color: var(--color-primary);
        }

        .day.lastMonth{
          font-weight: initial;
        }

       .day.selected {
          outline: 3.5px solid var(--color-primary) !important;
          z-index: 1;
          border:none;
        }

        .day.selected > span{
          background-color: var(--color-primary);
          color: white;
        }

        .day.current:not(.selected) > span{
          color: var(--color-primary);
        }
        .selector-month {
          background-color: white;
          border-radius: 10px;
          border:none;
          font-weight: bold;
          padding: 10px 40px 10px 10px;
          font-size: 16px;
          color: var(--color-primary);
          display: flex;
          align-items: center;
          position: relative;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }

        .container-flechas{
        display: flex;
        flex-direction: row;
        gap: 10px;

        }
        .day.no-exercise {
          background-color: #F1F5F8 !important;
        }
        .exercise-bar{
          background-color: var(--color-primary);
          height: 4px;
          margin-bottom: 2px;
          width: 100%;
          border-radius: 10px;
        }
        @media (max-width: 1200px) {
          .day{
            height: 60px;
            padding: 5px;
          }
          .content {
            height: 72%;
          }
          .dayName{
             margin-left: 0px;
            text-align: center;
          }
          .header{
            flex-direction: column;
          }
          .dayNames{
            width: 99.8%;
            border-radius: 25px 25px 0 0;
          }
          .exercise-bars-container{
            display: grid;
            grid-template-columns: repeat(2, 1fr); /* Dos columnas */
            gap: 1px;
            width: 100%;
          }
          .exercise-bar{
            height: 2px;
            margin-bottom: 0.5px;
            width: 100%; /* Ahora ocupa el 100% de su columna */
            border-radius: 5px;
          }
        }
      </style>

      <div class="header">
      <div class="container-flechas">
       <div class="flechas">
          <rehub-icon name="arrow" class="previous" rotate="-90" size="20"></rehub-icon>
          <rehub-icon name="arrow" class="next" rotate="90" size="20"></rehub-icon>
       </div>
        <h1></h1>
      </div>
        <select id="monthSelector" class="selector-month"></select>
      </div>

      <div class="dayNames"></div>

      <div class="content"></div>
    `;

    let daysNames = CalendarUtils.getDaysNames(this.moment);
    let namesElement = this.shadowRoot.querySelector(".dayNames");

    daysNames.forEach((name)=>{
      namesElement.innerHTML += html`
      <div class="dayName">
        ${this.upperCase(name).replace(".", "")}
      </div>`;
    });

    let previousElement = this.shadowRoot.querySelector(".previous");
    let nextElement = this.shadowRoot.querySelector(".next");

    previousElement.onclick = this.onPreviousMonthPressed.bind(this);
    nextElement.onclick = this.onNextMonthPressed.bind(this);

    let monthSelector = this.shadowRoot.querySelector("#monthSelector");
    let monthNames = CalendarUtils.getMonthNames(this.moment);
    monthNames.forEach((month, index) => {
      let option = document.createElement("option");
      option.value = index;
      option.text = this.capitalize(month);
      if (index === this.today.month() + this.selectedMonth) {
        option.selected = true;
      }
      monthSelector.appendChild(option);
    });

    monthSelector.onchange = (event) => {
      this.selectedMonth = parseInt(event.target.value) - this.today.month();
      this.onMonthChanged();
      this.renderDays();
    };

    this.rendered = true;

    this.renderDays();
  }

  renderDays(){
    if(!this.rendered) return;

    let currentMonth = this.today.clone().add(this.selectedMonth, 'month');
    let lastMonth = this.today.clone().add(this.selectedMonth - 1, 'month');

    let daysInMonth = CalendarUtils.getDaysInMonth(currentMonth);
    let daysInLastMonth = CalendarUtils.getDaysInMonth(lastMonth);
    let firstDayOfMonth = CalendarUtils.getFirstDayOfMonth(currentMonth);
    let firstDayOfMonthOffset = CalendarUtils.getFirstDayOfMonthOffset(currentMonth);

    let contentElement = this.shadowRoot.querySelector(".content");

    contentElement.innerHTML = "";

    // Update month selector
    let monthSelector = this.shadowRoot.querySelector("#monthSelector");
    monthSelector.value = currentMonth.month();

    for(let i = 0; i < firstDayOfMonthOffset; i++){
      let day = firstDayOfMonth.clone().subtract(firstDayOfMonthOffset - i, 'days');
      let barsHTML = "";
      for (let j = 0; j < this._events.pendingBars; j++) {
          barsHTML += `<div class="exercise-bar"></div>`;
      }

      contentElement.innerHTML += html`
      <div class="day lastMonth" data-day="${day.toISOString()}">
        <span>${daysInLastMonth - firstDayOfMonthOffset + i + 1}</span>
        <rehub-icon name="shape_circle" size="0" style="color: transparent; padding-left:10px; padding-top:5px;"></rehub-icon>
                <div class="exercise-bars-container">${barsHTML}</div>
      </div>`;
    }

    for(let i = 0; i < daysInMonth; i++){
      let day = firstDayOfMonth.clone().add(i, 'days');
      contentElement.innerHTML += html`
      <div class="day" data-day="${day.toISOString()}">
        <span>${i + 1}</span>
        <rehub-icon name="shape_circle" size="0" style="color: transparent; padding-left:10px; padding-top:5px; "></rehub-icon>
      </div>`;
    }

    let dayElements = this.shadowRoot.querySelectorAll(".day");

    dayElements.forEach((dayElement)=>{
      let day = dayElement.dataset.day;
      let isLastMonth = dayElement.classList.contains("lastMonth");
      if(day && !isLastMonth) dayElement.onclick = this.onDayPressed.bind(this, day);

      this.renderCurrentDay(dayElement);
      this.renderSelectedDay(dayElement);
      this.renderEvents(dayElement);
    });

    this.renderMonth(currentMonth);
  }

  renderCurrentDay(dayElement){
    dayElement.classList.remove("current");
    let day = dayElement.dataset.day;
    let current = day && CalendarUtils.isToday(this.moment, this.moment(day));
    if(current) dayElement.classList.add("current");
  }

  renderSelectedDay(dayElement) {
    dayElement.classList.remove("selected");
    let day = dayElement.dataset.day;
    let selected = day && CalendarUtils.isSameDay(this.moment(day), this.selectedDay);
    if (selected) {
      dayElement.classList.add("selected");
    }
  }


  renderMonth(currentMonth){
    let monthElement = this.shadowRoot.querySelector(".header h1");
    monthElement.innerHTML = this.capitalize(currentMonth.format('MMMM YYYY'));
  }

  renderEvents(dayElement) {
    let day = this.moment(dayElement.dataset.day).format('YYYY-MM-DDTHH:mm:ss[Z]');
    let event = this._events[day];

    // Limpiar contenido previo de barras (evitar acumulación)
    let existingBarsContainer = dayElement.querySelector(".exercise-bars-container");
    if (existingBarsContainer) {
        existingBarsContainer.remove();
    }

    // Si el día tiene eventos, mostramos las barras y le quitamos el fondo gris
    if (event && event.bars && event.bars.pendingBars.length > 0) {
        let barsHTML = "";
        // Limitamos el número de barras a 15
        const numberOfBars = Math.min(event.bars.pendingBars.length, 15);
        for (let i = 0; i < numberOfBars; i++) {
            barsHTML += `<div class="exercise-bar"></div>`;
        }

        let barsContainer = document.createElement("div");
        barsContainer.classList.add("exercise-bars-container");
        barsContainer.innerHTML = barsHTML;

        dayElement.appendChild(barsContainer);
        dayElement.classList.remove("no-exercise");
    } else {
        dayElement.classList.add("no-exercise");
    }
  }

};