export type SelfServiceProgram = SelfServiceProgramName & SelfServiceTodayExercises & {
  therapyId: string;
  diagnosisId: string;
  from: string;
  to: string;
  totalExercises: number;
  finished: boolean;
  imageURL: string;
  therapyName: string;
  pillDescription: string;
}



export type SelfServiceProgramName = {
  type: string;
  programName: string;
  programGroupName: string;
  groupId: string;
  categoryId: string;
  pillId?: string;
}

export type SelfServiceTodayExercises = {
  done: number;
  inProgress: number;
  total: number;
  allDone: boolean;
}




export class SelfServiceUtils {

  public static getProgramsFromTherapies(therapies: any, diagnosisInfo: any, categoryData: any,pillData:any,environment:any, moment: any, ): SelfServiceProgram[]{
    let programs: SelfServiceProgram[] = [];

    const ProgramImage: any = {
      "c5242d1e-c4bc-11ed-97bd-9edd6e571790": environment.web_resources_url + "/self-service/questions/images/program_0.png",
      "d342bc08-c4bc-11ed-97bd-9edd6e571790": environment.web_resources_url + "/self-service/questions/images/program_1.png",
      "e1f160ce-c4bc-11ed-97bd-9edd6e571790": environment.web_resources_url + "/self-service/questions/images/program_2.png",
      "f01bcb6c-c4bc-11ed-97bd-9edd6e571790": environment.web_resources_url + "/self-service/questions/images/program_3.png",
      "853d1f3e-8227-11ee-ac68-9281ecd21456": environment.web_resources_url + "/self-service/questions/images/workout_home_front.jpg",
    }

    programs = therapies.map((therapy: any)=>{
      let diagnosisInfoTherapy =  diagnosisInfo.diagnosisInfo[therapy.diagnosisId].data[therapy.therapyId];
      let endDayTherapy = moment(diagnosisInfoTherapy.to).endOf('day');

      let programName = this.getProgramNameFromTherapyName(therapy.therapyName,categoryData,pillData);
      let todayExercises = this.getTodayExercisesFromDiagnosisInfo(therapy.therapyId, diagnosisInfo);
      let imageURL = "";
      let pillDescription = "";
      if (programName.type=="QT") {

        imageURL = ProgramImage[programName.programName] + "?v="+ environment.media_version;
      } else if  (programName.type=="PILL") {
        imageURL= environment.web_resources_url +"/self-service/pills/images/"+ pillData[programName.pillId].imageId +".png"+ "?v="+ environment.media_version;
        pillDescription=pillData[programName.pillId].pillDescription;
      }

      let program: SelfServiceProgram = {
        therapyId: therapy.therapyId,
        diagnosisId: therapy.diagnosisId,
        from: diagnosisInfoTherapy.from,
        to: diagnosisInfoTherapy.to,
        totalExercises: diagnosisInfoTherapy.totalExercises,
        finished: moment().isAfter(endDayTherapy),
        imageURL: imageURL,
        therapyName: therapy.therapyName,
        pillDescription: pillDescription,
        ...programName,
        ...todayExercises
      }

      return program;
    });

    programs = programs.filter((program: SelfServiceProgram)=>program.therapyName != null);

    return programs;
  }

  public static sortProgramsByAllDone(programs: SelfServiceProgram[]): SelfServiceProgram[]{
    let sortedPrograms = programs.sort((a: SelfServiceProgram, b: SelfServiceProgram)=>{
      if(a.allDone && !b.allDone) return 1;
      if(!a.allDone && b.allDone) return -1;
      return 0;
    });

    return sortedPrograms;
  }

  public static getProgramNameFromTherapyName(therapyName: string,categoryData:any, pillData:any ): SelfServiceProgramName{
    let parts = therapyName == null ? ["", "", ""," "] : therapyName.split("*");
    let result : SelfServiceProgramName;
    if (parts[0]=="QT") {
      result={
        type: parts[0],
        programName: parts[1],
        programGroupName: parts[2],
        categoryId: "",
        groupId: ""
      }
    } else if  (parts[0]=="PILL") {
      let pillId:string = parts[2]+"_"+parts[1];
      result ={
        type: parts[0],
        programName: pillData[pillId].pillName,
        programGroupName: categoryData[parts[2]].categoryName,
        categoryId: parts[2],
        groupId: parts[1],
        pillId: pillId
      }
    } else {
      result={
        type: "",
        programName: "",
        programGroupName: "",
        categoryId: "",
        groupId: ""
      }
    }
    return result;

  }

  public static getTodayExercisesFromDiagnosisInfo(therapyId: string, diagnosisInfo: any): SelfServiceTodayExercises{
    let todayExercises = {
      ...diagnosisInfo.todayExercises[therapyId]
    }

    todayExercises.allDone = todayExercises.all && todayExercises.all.done == todayExercises.total;

    return todayExercises;
  }
}