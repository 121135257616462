import { env } from './.env';
import * as npm from '../../package.json';

export default {
  ...env,
  name: npm.name,
  web_resources_url: 'https://web-resources.dycare-services.com',
  assets_url: 'https://web-resources.dycare-services.com',
  version: env.npm_package_version,
  media_version: '1.78.0',
  conditions_of_use_version: '2',
  privacy_policy_version: '2',
  haria_conditions_of_use_version: '1',
  haria_privacy_policy_version: '1',
  center_VITALHUB: '',
  title: 'ReHub',
  favicon: 'https://web-resources.dycare-services.com/favicon/favicon.ico',
  defaultInsurer: null,

  defaultLanguage: "english",
  supportedLanguages: [
    {name: "english", code: "en", flag: "gb"},
    {name: "spanish", code: "es", flag: "es"},
    {name: "italian", code: "it", flag: "it"},
    {name: "catalan", code: "ca", flag: "ca"},
    // {name: "french", code: "fr", flag: "fr"},
    // {name: "german", code: "de", flag: "de"},
    {name: "portuguese", code: "pt", flag: "pt"},
    // {name: "dutch", code: "nl", flag: "nl"},
    {name: "spanish-mexico", code: "esMX", flag: "esMX"}
  ],
  contact_email: null,
  contact_phone_number_call: null,
  logo: null,
  login_url: 'https://login-site.dycare-services.com',
  login_url_backend: 'https://login.dycare-services.com',
  signup_url_backend: null,
  chromecastNamespace: 'urn:x-cast:ch.cimnine.cromecast-test.text',
  chromecastAppId: '83CA01A7',
  wsController: 'wss://gbpe1o6ezj.execute-api.eu-west-1.amazonaws.com/dev',

  firebaseConfig : {
    apiKey: "AIzaSyB0cf3P_RrOwhd9Pryiu9B_xJ5JLrI49Bo",
    authDomain: "rehub-push-notifications.firebaseapp.com",
    projectId: "rehub-push-notifications",
    storageBucket: "rehub-push-notifications.appspot.com",
    messagingSenderId: "479326484583",
    appId: "1:479326484583:web:12d7c3abcf856e293cfcc2",
    measurementId: "G-17M1KJ2WMN"
  }
}