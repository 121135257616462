
import { API, APIResponseInterceptor, LoginAPI } from "./api";
import { SharedCoachService } from "./coach/coach.service";
import { SharedAuthenticationService } from "./service/authentication.service";
import { SharedCredentialsService } from "./service/credentials.service";
import { SharedDeployInfoService } from "./service/deploy-info.service";
import { SharedFeatureFlagsService } from "./service/feature-flags.service";
import { SharedI18nService } from "./service/i18n.service";
import { SharedThemeService } from "./service/theme.service";
import { SharedUtils } from "./utils/utils";


export class SharedAppConfig{

  constructor(
    protected translationBaseURL: string,
    protected isDevMode: boolean,
    protected environment: any,
    protected i18nService: SharedI18nService,
    protected credentialsService: SharedCredentialsService,
    protected authenticationService: SharedAuthenticationService,
    protected deployInfoService: SharedDeployInfoService,
    protected featureFlagsService: SharedFeatureFlagsService,
    protected themeService: SharedThemeService,
    protected coachService: SharedCoachService
  ){}

  private languages = {
    "spanish": "es",
    "italian": "it",
    "catalan": "ca",
    "english": "en",
    "french": "fr",
    "german": "de",
    "portuguese": "pt",
    "dutch": "nl",
    "spanish-mexico": "esMX"
  }

  loadConfig(){
    console.log("LOAD CONFIG")
    //let language:string = lang ? lang : this.getDefaultLanguage();

    API.setInterceptor(new APIResponseInterceptor(this.credentialsService, this.authenticationService, this.environment.client_login));

    this.i18nService.setDefaultLanguage(this.environment.defaultLanguage);
    this.i18nService.setSupportedLanguages(this.environment.supportedLanguages);
    this.i18nService.setLanguage(this.getDefaultLanguage());

    let batch = {
      deployInfo: this.getDeployInfo(),
      featureFlags: this.getFeatureFlags(),
      coachMessages: this.getCoachMessages()
    };

    return API.allFromObject(batch).then((result: any)=>{
      if(result.coachMessages) this.coachService.setMessages(result.coachMessages);
      //console.log("app config result.coachMessages")
      //console.log(result.coachMessages)
      if(result.deployInfo) this.deployInfoService.set(result.deployInfo);
      if(result.featureFlags) this.featureFlagsService.set(result.featureFlags);
    });
  }

  getDeployInfo(){
    if(this.isDevMode) return Promise.resolve({});
    else return API.getWithoutAuth("deploy_info.json", {});
  }

  getFeatureFlags(){
    if(this.isDevMode) return Promise.resolve({});
    else return API.getWithoutAuth("feature_flags.json", {});
  }

  getCoachMessages(){
    const version = this.environment.media_version;
    return API.getWithoutAuth(`${this.environment.web_resources_url}/editor-assets/${version}/coach/coach_messages.json`);
  }

  getCoachMessagesLength(){
    const version = this.environment.media_version;
    let languages;
    if(SharedUtils.isStage(this.environment.stage, ["demo3"]) || SharedUtils.isStage(this.environment.stage, ["test2"])){
      languages = ["en", "es", "it", "ca", "esMX", "fr", "de", "pt", "nl"];
    }else  languages = ["en", "es", "it", "ca", "esMX"];

    let batch: any = {};

    languages.forEach((language: string) => {
      batch[language] = API.getWithoutAuth(`${this.environment.web_resources_url}/editor-assets/${version}/coach/coach_messages_length_${language}.json`);
    });

    return API.allFromObject(batch);
  }

  getSingleLangCoachMessagesLength(language: string){
    const version = this.environment.media_version;
    let batch: any = {};
    let lang = this.languages[language];
    batch[lang] = API.getWithoutAuth(`${this.environment.web_resources_url}/editor-assets/${version}/coach/coach_messages_length_${lang}.json`);

    return API.allFromObject(batch);
  }

  getTranslations(){
    const version = this.environment.media_version;

    let files = ["general", "protocols"];
    let languages:any;
    if(SharedUtils.isStage(this.environment.stage, ["demo3"]) || SharedUtils.isStage(this.environment.stage, ["test2"])){
      languages = ["en", "es", "it", "ca", "esMX", "fr", "de", "pt", "nl"];
    }else  languages = ["en", "es", "it", "ca", "esMX"];

    let batch: any = {};

    files.forEach((file: string) => {
      languages.forEach((language: string) => {
        let key = file + "_" + language;
        batch[key] = API.getWithoutAuth(`${this.environment.web_resources_url}/editor-assets/${version}/i18n/${key}.json`);
      });
    });

    return API.allFromObject(batch);
  }

  getSingleTranslation(language: string){
    const version = this.environment.media_version;
    let files = ["general", "protocols"];
    let lang = this.languages[language];
    let batch: any = {};

    files.forEach((file: string) => {
        let key = file + "_" + lang;
        batch[key] = API.getWithoutAuth(`${this.environment.web_resources_url}/editor-assets/${version}/i18n/${key}.json`);
    });

    return API.allFromObject(batch);
  }

  getDefaultLanguage(){
    let lang = navigator.language;
    let saved = localStorage.getItem('language');
    let foundLanguage = this.environment.supportedLanguages.find((l: any)=> lang.includes(l.code) || lang.includes(l.flag));
    let defaultLanguage = foundLanguage ? foundLanguage.name : this.environment.defaultLanguage;
    return saved || defaultLanguage;
  }

  refreshCredentials(): Promise<any>{

    let code = SharedUtils.getUrlParameter("code");

    let credentials = this.credentialsService.getCredentials();

    // Si llega un código y ya habia una sesión iniciada se limpia la sesión
    if(code && credentials){
      this.credentialsService.clearCredentials();
      credentials = null;
    }

    // Si tiene credenciales temporales hay que limpiarlos
    //TODO! limpiar credenciales cuando sean temp
    if(credentials && credentials.temp) {
      this.credentialsService.clearCredentials();
      credentials = null;
    }

    // Si no tiene credenciales tiene que hacer login
    if(!credentials){
      return Promise.resolve();
    }

    let params = {
      client: this.environment.client_login,
      refreshToken: credentials.refreshToken
    }

    return LoginAPI.refreshToken(params.client, params.refreshToken).then((result: any) => {
      console.log("REFRESH")
      let data = {
        idToken: result.IdToken,
        accessToken: result.AccessToken
      };

      this.credentialsService.appendCredentials(data);

      return this.authenticationService.getAndSaveLoginInfo();
    });
  }

  redirectToLogin(){
    this.credentialsService.clearCredentials();
    this.authenticationService.redirectToLogin();
  }
}