
<div class="mod-container" >
  <div class="mod-box">

  <div class="modal-header">
    <div class="d-flex flex-row">
      <img class="modal-warning"  src="https://web-resources.dycare-services.com/self-service/images/warning.png" alt="warning" />
      <h2 class="modal-title"  [ngStyle]="{'font-size': isMobile ? '1rem' : '1.5rem'}"  >
        {{ 'modal_haria_start_title' | translate }}
      </h2>
    </div>
    <!--
    <a class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <rehub-icon name="close" size="15"></rehub-icon>
    </a>-->
  </div>

  <div class="modal-body feet-body d-flex flex-column" [ngClass]="{ 'mt-0': isMobile, 'mt-3':!isMobile }">
    <div class="d-flex flex-row">
      <div class="scroll-body">
        <p>{{ 'modal_haria_start_body_1' | translate }} <strong>{{ 'modal_haria_start_body_2' | translate }}</strong>. {{ 'modal_haria_start_body_3' | translate }}.</p>
        <p>{{ 'modal_haria_start_body_4' | translate }} <u><a target="_blank" href="https://web-resources.dycare-services.com/self-service/files/conditions_of_use_es_1.html">{{ 'modal_haria_start_body_5' | translate }}</a></u> {{ 'modal_haria_start_body_6' | translate }} <u><a target="_blank" href="https://web-resources.dycare-services.com/self-service/files/privacy_policy_patient_es_1.html">{{ 'modal_haria_start_body_7' | translate }}</a></u>.</p>
      </div>
    </div>
  </div>

  <div class="modal-footer border-0 pt-0 d-flex justify-content-end" [ngClass]="{ 'mt-0': !isMobile, 'mt-3':isMobile }">
    <button type="button" class="btn btn-primary btn-color-w d-flex flex-row align-items-center justify-content-center" (click)="close()">
      <span>{{"general_cancel" | translate}}</span>
    </button>
    <button type="button" class="btn btn-primary btn-color-b btn-lg d-flex flex-row align-items-center justify-content-center" (click)="closeForver()">
      <span>{{"general_accept" | translate}}</span>
    </button>
  </div>

  </div>
</div>