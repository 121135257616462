<div class="modal-header2" [ngClass]="{'modal-header-mobile': isMobile}">
  <div class="icon-container">
    <img class="modal-icon" [ngClass]="{'modal-icon-mobile': isMobile}" [src]="url" />
  </div>
  <a class="chat-back-button" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<!-- Contenedor del modal -->
<div class="body-modal scrollable-content" [ngClass]="{'body-modal-mobile': isMobile}">
  <div class="d-flex flex-row justify-content-between mb-3" [ngClass]="{'flex-column': isMobile}">
    <div class="d-flex flex-column align-items-start" [ngClass]="{'gap-10': !isMobile}">
      <h2 [ngClass]="isMobile ? 'modal-title-mobile' : 'modal-title'">
        {{ "patient_modal_finished_title" | translate }}
      </h2>
      <p [ngClass]="isMobile ? 'modal-subtitle-mobile' : 'modal-subtitle'">
        {{ "patient_modal_finished_text" | translate }}
      </p>
    </div>

    <div class="d-flex flex-row" [ngClass]="{'flex-column': isMobile, 'align-items-center': !isMobile}">
      <div class="option-box" style="height:fit-content;">
        <div class="stats-container align-items-center">
          <div class="stat-box d-flex flex-row align-items-center mb-2">
            <rehub-icon name="time_2" size="20"></rehub-icon>
            <h3 class="stat-label mb-0 bold-title">{{ "patient_modal_finished_daily_time" | translate }}</h3>
          </div>
          <h3 class="stat-value color-tema" style="text-align: left;">
            {{ data.time }}
          </h3>
        </div>
      </div>
    </div>
  </div>


  <!-- Sección de ejercicios realizados -->
  <div class="options-container">
    <div class="d-flex flex-column option-box" [ngClass]="{'scrollable-exercises': data?.protocolsinfo.length > 2}">
      <div class="d-flex flex-row align-items-center mb-2" style="gap:10px;">
        <rehub-icon name="tool_weight" size="20"></rehub-icon>
        <h3 class="mb-0 bold-title">{{ "patient_modal_finished_done_exercise" | translate }}</h3>
      </div>
      <div class="d-flex flex-column text-left" [ngClass]="{'flex-row': !isMobile}">
        <div *ngFor="let protocol of data?.protocolsinfo" class="protocol-item">
          <div class="stats-container">
            <div class="stat-box d-flex flex-row align-items-center mb-2">
              <h2 class="stat-label mb-0">{{ protocol.protocolName | translate }}</h2>
            </div>
            <h3 class="stat-value color-tema text-left">
              {{ protocol.iterations }} {{ "patient_modal_finished_daily_iterations" | translate }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Footer sticky -->
<div class="modal-footer sticky-footer" [ngStyle]="!isMobile ? {'border-radius': '0 0 50px 50px'} : {}">
  <a class="cancel-button" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    {{ "patient_modal_finished_daily_close" | translate }}
  </a>
  <button type="button" class="btn-primary" (click)="visitProgress()">
    {{ "realtime_step_continue" | translate }}
  </button>
</div>
