import { Component, OnInit, Input, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { ModalService } from '@app/@core/service/modal.service';
import { CoachService } from '@app/@core/service/coach.service';
import { I18nService } from '@app/i18n';
import { Utils } from '@app/utils';
import { SharedUtils } from '@rehub-shared/utils/utils';
import { ProtocolUtils } from '@rehub-shared/utils/protocol-utils';
import { CredentialsService } from '@app/@core/auth';

@Component({
  selector: 'self-service-pills-form-modal-component',
  templateUrl: './self-service-pills-form-modal.component.html',
  styleUrls: ['./self-service-pills-form-modal.component.scss'],
  host: { class: "modal-100" }
})

export class SelfServicePillsFormModalComponent implements OnInit {

  @Input() data: any;
  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef>;

  Utils = Utils;
  SharedUtils = SharedUtils;
  openPanels: string[] = [];
  categories: Record<string, any>;
  pills: any;
  selected: string[] = [];
  allCategories: string[] = [];
  currentIndexGroup: number = 0;
  categoriesByGroup: any[] = [];
  favouriteCategoryIds: string[] = [];
  isMobile: boolean = false;
  showIntro: boolean = true; // << nueva variable

  constructor(
    private modalService: ModalService,
    private i18nService: I18nService,
    private coachService: CoachService,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit(): void {
    this.categories = Object.values(this.data.categories);
    const permissions = this.credentialsService.getPermissions();
    const permissionList = this.getMatchingKeys(permissions);
    const allPills = this.removePrefix(permissionList);

    this.categories = this.categories.filter( (category: any) => allPills.includes(category.categoryId) );
    this.pills = this.data.pills;
    this.isMobile = SharedUtils.isMobile();


    this.allCategories = this.removeSuffixAndGetUnique(permissionList);
    this.allCategories = this.removePrefix(this.allCategories);
    console.log(this.allCategories);

    if (this.allCategories.length === 1) {
      this.toggleSelection(this.allCategories[0]);
      this.showIntro = false;
      this.nextStep();
    }
  }

  toggleSelection(objetivo: string): void {
    const index = this.selected.indexOf(objetivo);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else if (this.selected.length < 3) {
      this.selected.push(objetivo);
    }
  }

  getMatchingKeys(obj: Record<string, any>): string[] {
    return Object.keys(obj).filter(key => key.startsWith("SS_pill_") && obj[key] === true);
  }

  removeSuffixAndGetUnique(strings: string[]): string[] {
    return Array.from(new Set(strings.map(str => str.replace(/_\d+$/, ""))));
  }

  removePrefix(strings: string[]): string[] {
    return strings.map(str => str.replace(/^SS_pill_/, ""));
  }

  nextStep(): void {
    if (this.showIntro) {
      this.showIntro = false;
      return;
    }

    if (this.currentIndexGroup === 0) {
      this.currentIndexGroup = 1;
      this.chargeCategoriesByGroup();
    } else {
      const newSelections = this.categoriesByGroup.filter(cat => cat.selected).map(({ categoryId }) => categoryId);
      this.favouriteCategoryIds = [...this.favouriteCategoryIds, ...newSelections];

      if (this.currentIndexGroup < this.selected.length) {
        this.currentIndexGroup++;
        this.chargeCategoriesByGroup();
      } else {
        const localStorageItem = this.credentialsService.getInfo().patientId + "_favourite_pill_categories";
        localStorage.setItem(localStorageItem, JSON.stringify(this.favouriteCategoryIds));
        this.onClose();
      }
    }
  }

  previousStep(): void {
    if (this.currentIndexGroup > 0) {
      this.currentIndexGroup--;
      this.chargeCategoriesByGroup();
    }
  }

  toggleSelectionCategory(category: any) {
    const selected = this.categoriesByGroup.filter(cat => cat.selected).length;
    if (category.selected) {
      category.selected = false;
    } else if (selected < 3) {
      category.selected = true;
    }
  }

  chargeCategoriesByGroup(): void {
    const grupoActual = this.selected[this.currentIndexGroup - 1];
    this.categoriesByGroup = Object.values(this.categories).filter((cat: any) =>
      cat.categoryId.startsWith(grupoActual)
    ).map(cat => ({
      ...cat,
      selected: this.favouriteCategoryIds.includes(cat.categoryId)
    }));

    if (this.categoriesByGroup.length === 1) {
      this.categoriesByGroup[0].selected = true;
      this.nextStep();
    }
  }

  isPanelOpen(material: string): boolean {
    return this.openPanels.includes(material);
  }

  onClose(): void {
    this.modalService.close();
  }

  startNextExercise(): void {
    this.modalService.callback(true);
  }

  onOverlayClick(event: MouseEvent): void {
    this.onClose();
  }
}
