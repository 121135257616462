
import { AudioManager } from "../utils/audio-manager";
import { SharedEventBusService } from "./eventbus.service";
import { SharedLocalSettingsService } from "./../utils/local-settings.service";
import { ChatAPI } from "@rehub-shared/api";

export interface IVoiceControlEvent {
  onKeyWordDetected(): void;
  onCommandProcessing(): void;
  onCommandProcessed(command:any): void;
  onCommandNotProcessed(coach_message: string, component_name: string): void;
}

export type VoiceComponentContext = {
  componentName: string,
  commands: string[],
}

export type VoiceComponentCallback = {
  componentName: string,
  callback: IVoiceControlEvent
}

const MAX_TRANSCRIPT_LENGTH = 15;
const MAX_TIME_LIMIT = 20 * 1000;
const INIT_SOUND: string = "realtime_min";
const END_SOUND: string = "realtime_max";
const KEYWORD: string = "DIANA";
export class SharedVoiceControlService {
  private componentsContexts: Array<VoiceComponentContext> = [];
  private componentsCallbacks: Array<VoiceComponentCallback> = [];

  private keyWordFound: boolean = false;

  private speechRecognitionList: any;
  private recognition: any;
  private audioManager: AudioManager;

  private serviceStarted: boolean = false;


  private microIconHeaderEventBus: any;
  private isEnabled: boolean = false;

  private currentTimeout: any;

  private error: boolean = false;

  constructor(
    private eventBus: SharedEventBusService,
    private localSettingsService: SharedLocalSettingsService,
  ) {
    this.componentsContexts = new Array<VoiceComponentContext>();
    this.componentsCallbacks = new Array<VoiceComponentCallback>();
    this.initMicrophoneStatus();
  }

  initMicrophoneStatus(){
    this.microIconHeaderEventBus = this.eventBus.subscribe("MICRO_ENABLE", (data: any) => {
      console.log("MICRO_ENABLE event received:", data);
      const enabled = data.message === "enable";
      if (enabled && !this.recognition) {
        this.initRecognition();
      }
      this.setMicroStatus(enabled);
    });
  }

  setMicroStatus(status: boolean) {
    this.isEnabled = status;
    this.localSettingsService.set({ ...this.localSettingsService.get(), microDisabled: !status });

    if (this.recognition) {
      if (!status) {
        this.recognition.abort();
      } else if (status ) {
        this.startRecognition();
      }
    }
  }

  generateContext() {
    return this.componentsContexts.reduce((context, voiceComponentContext) => {
      Object.assign(context.commands, voiceComponentContext.commands);
      return context;
    }, { commands: {}});
  }

  generateContextList(){
    return this.componentsContexts.reduce((commandList, voiceComponentContext) => {
      commandList.push(...Object.values(voiceComponentContext.commands));
      return commandList;
    }, [] as string[]);
  }

  addComponent(componentName:string, commands: string[], callback: IVoiceControlEvent) {
    this.addComponentContext(componentName, commands);
    this.addComponentCallback(componentName, callback);
  }

  addComponentContext(componentName:string, commands: string[]) {
    this.componentsContexts.push({
      commands: commands,
      componentName: componentName,
    });
  }

  addComponentCallback(componentName:string, callback: IVoiceControlEvent) {
    this.componentsCallbacks.push({
      callback: callback,
      componentName: componentName
    });
  }

  removeComponent(windowName:string) {
    this.removeContext(windowName);
    this.removeCallback(windowName);
  }

  removeContext(windowName:string) {
    this.componentsContexts = this.componentsContexts.filter(context => context.componentName !== windowName);
  }

  removeCallback(windowName:string) {
    this.componentsCallbacks = this.componentsCallbacks.filter(callback => callback.componentName !== windowName);
  }

  init(assets_url: string, cache_param: string) {
    if (this.serviceStarted) return;

    this.serviceStarted = true; // Set serviceStarted early to prevent re-entry

    this.audioManager = new AudioManager(assets_url, cache_param, [INIT_SOUND, END_SOUND]);

    // Initialize WebSocket and Speech Recognition in parallel
    Promise.all([this.initRecognition()])
      .then(() => {
        this.startRecognition();
      })
      .catch((error) => {
        console.error("Initialization error:", error);
        this.serviceStarted = false; // Reset serviceStarted on failure
      });
  }

  initRecognition(){
    const w = window as any;
    this.recognition = new (w.SpeechRecognition || w.webkitSpeechRecognition)();

    this.speechRecognitionList = new w.webkitSpeechGrammarList();
    console.log("initRecognition() speechRecognitionList:", this.speechRecognitionList);

    const grammar = '#JSGF V1.0; public <fase> = Diana *;';
    this.speechRecognitionList.addFromString(grammar, 1);

    const currentLanguage = this.getSpeechAPILanguageCode();
    console.log("VoiceControl::startRecognition() language " + currentLanguage);
    this.recognition.lang = currentLanguage;

    this.recognition.interimResults = true;
    this.recognition.maxAlternatives = 1;
    this.recognition.continuous = true;
    this.recognition.grammars = this.speechRecognitionList;

    this.recognition.onaudioend = () => {
      console.log("-> onaudioend");

      this.recognition.abort();
    };

    this.recognition.onaudiostart = () => {
      console.log("-> onaudiostart");
    };

    this.recognition.onend = () => {
      if (this.error) {
        console.log("On end. Recognition error, ending recognition");
        return;
      }
      console.log("-> onend");
      if (this.isEnabled) {
        this.startRecognition();
      }
    }

    this.recognition.onstart = () => {
      console.log("-> onstart");
    }

    this.recognition.onerror = (event: any) => {
      console.error("Recognition error:", event);
      switch (event.error) {
        case 'no-speech':
          console.log("No speech detected");
          break;
        case 'audio-capture':
          console.log("No microphone detected");
          this.error = true;
          break;
        case 'not-allowed':
          console.log("Microphone access not allowed");
          this.error = true;
          break;
        case 'network':
          console.log("Network error");
          break;
        case 'aborted':
          // console.log("Aborted");
          // this.error = true;
          // debugger;
          break;
        case 'service-not-allowed':
          console.log("Service not allowed");
          this.error = true;
          debugger;
          break;
        case 'bad-grammar':
          console.log("Bad grammar");
          break;
        case 'language-not-supported':
          console.log("Language not supported");
          break;
        default:
          console.log("Unknown error");
          this.error = true;
          debugger;
          break;
      }
    }

    this.recognition.onresult = (event: any) => {
      if (!this.isEnabled) return;
      console.log("Recognition onresult, event:", event);

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript.toUpperCase();
        const isFinal = event.results[i].isFinal;
        console.log("isFinal", isFinal, `Transcript ${i} of ${event.results.length}:`, transcript);

        if (transcript.includes(KEYWORD) && !this.keyWordFound) {
          this.keyWordFound = true;
          if(this.audioManager){
            this.audioManager.play(INIT_SOUND);
          }
          this.componentsCallbacks.forEach(callback => callback.callback.onKeyWordDetected());
          this.controlTimeout();
        } else if (!this.keyWordFound) {
          console.log("Keyword not found, ignoring transcript");
          return;
        }

        let trimmedTranscript = transcript.split(KEYWORD).pop();
        const wordCount = trimmedTranscript.trim().split(/\s+/).length;
        if (wordCount > MAX_TRANSCRIPT_LENGTH) {
          console.log("Too many words in the transcript, stopping recognition");
          this.recognition.abort();
          return;
        }

        // if its not a letter, remove the first character
        if (trimmedTranscript.length > 0 && !/[a-zA-Z]/.test(trimmedTranscript[0])) {
          trimmedTranscript = trimmedTranscript.substring(1);
        }

        // if the last character is not a letter, remove it
        if (trimmedTranscript.length > 0 && !/[a-zA-Z]/.test(trimmedTranscript[trimmedTranscript.length - 1])) {
          trimmedTranscript = trimmedTranscript.substring(0, trimmedTranscript.length - 1);
        }

        if (isFinal && this.keyWordFound) {
          if(this.audioManager){
            this.audioManager.play(END_SOUND);
          }
          const contextGenerated = this.generateContextList();
          console.log("Context generated:", contextGenerated);
          this.componentsCallbacks.forEach(callback => callback.callback.onCommandProcessing());

          if (this.isEnabled && trimmedTranscript.length > 0) {
            const json ={
              "voice": trimmedTranscript,
              "commands": contextGenerated,
              "lang": this.getLanguageCode(),
            };

            const timeBeginRequest = performance.now();
            ChatAPI.postVoiceControlCommand(json)
              .then((response: any) => {
                const timeEndRequest = performance.now();
                console.log("REQUEST time elapsed:", timeEndRequest - timeBeginRequest, "ms");
                console.log("response:", response);
                this.onMessage(response.result);
              }).catch((error: any) => {
                const timeEndRequest = performance.now();
                console.log("REQUEST ERROR time elapsed:", timeEndRequest - timeBeginRequest, "ms");
                console.log("error :", error);
              });
          } else {
            console.log("Transcript is empty or voice control is disabled, ignoring transcript");
          }
          this.keyWordFound = false;
        } else {
          console.log("Transcript is not final and keyword was found, ignoring transcript");
        }
      }
    };
  }

  controlTimeout() {
    this.discardTimeout();

    this.currentTimeout = setTimeout(() => {
      this.recognition.abort();
    }, MAX_TIME_LIMIT);
  }

  discardTimeout() {
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }
  }

  startRecognition() {
    console.log("VoiceControl::startRecognition()");
    this.recognition.start();
  }

  onMessage(msn: any) {
    const receivedCommand = msn.action;
    const threshold = msn.similarity;
    let commandFound = false;
    let firstComponentCallback: VoiceComponentCallback | undefined;
    for (const componentCallback of this.componentsCallbacks) {
      if (!firstComponentCallback) {
        firstComponentCallback = componentCallback;
      }

      const componentContext = this.componentsContexts.filter(context => context.componentName === componentCallback.componentName);
      componentContext.forEach(context => {
        if (threshold > 0.25 && componentContext && context.commands.includes(receivedCommand)) {
          componentCallback.callback.onCommandProcessed(receivedCommand);
          commandFound = true;
          return;
        }
      });

      if (commandFound) {
        break;
      }
    }

    if (!commandFound && firstComponentCallback) {
      firstComponentCallback.callback.onCommandNotProcessed("coach_voice_unknown_message", firstComponentCallback.componentName);
    }
  }

  onOpen(event: any) { }

  onClose(event: any) { }

  getLanguageCode() {
    try {
      const userLanguage = localStorage.getItem('language');

      switch (userLanguage) {
        case "spanish":
          return 'es';
        case "english":
          return 'en';
        case "italian":
          return 'it';
        case "catalan":
          return 'ca';
        case "french":
          return 'fr';
        case "german":
          return 'de';
        case "portuguese":
          return 'pt';
        case "dutch":
          return 'nl';
        case "spanish-mexico":
          return 'es';
        default:
          return 'es';
      }
    } catch (error) {
      console.error('Error getting language code:', error);
      return 'es';
    }
  }

  getSpeechAPILanguageCode() {
    try {
      const userLanguage = localStorage.getItem('language');

      switch (userLanguage) {
        case "spanish":
          return 'es-DO';
        case "english":
          return 'en-GB';
        case "italian":
          return 'it-IT';
        case "catalan":
          return 'ca-ES';
        case "french":
          return 'fr-FR';
        case "german":
          return 'de-DE';
        case "portuguese":
          return 'pt-PT';
        case "dutch":
          return 'nl-NL';
        case "spanish-mexico":
          return 'es-MX';
        default:
          return 'es-DO';
      }
    } catch (error) {
      console.error('Error getting language code:', error);
      return 'es-DO';
    }
  }
}