import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoaderComponent } from './loader/loader.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ToastComponent } from './toast/toast.component';
import { CoachComponent } from './coach/coach.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CalendarWeekComponent } from './calendar/calendar-week/calendar-week.component';
import { CalendarMonthComponent } from './calendar/calendar-month/calendar-month.component';
import { BarSelectorComponent } from './bar-selector/bar-selector.component';
import { FormBallsComponent } from './form/answerTypes/balls/form-balls.component';
import { FormSliderComponent } from './form/answerTypes/slider/form-slider.component';
import { FormCheckboxComponent } from './form/answerTypes/checkbox/form-checkbox.component';
import { PipeModule } from '@core/pipe/pipe.module';
import { ChatComponent } from './chat/chat.component';
import { AvatarComponent } from './avatar/avatar.component';
import { AvatarModule } from 'ngx-avatar';
import { CalendarDuringComponent } from './calendar/calendar-during/calendar-during.component';
import { ExerciseInfoComponent } from './exercise-info/exercise-info.component';
import { ConfirmModalComponent } from './modal/confirm-modal/confirm-modal.component';
import { PulsoWarningModalComponent } from './modal/pulsowarning-modal/pulsowarning-modal.component';
import { PatientTodayExercisesComponent } from './patient-today-exercises/patient-today-exercises.component';
import { InfoModalComponent } from './modal/info-modal/info-modal.component';
import { InstallRealtimeModalComponent } from './modal/install-realtime-modal/install-realtime-modal.component';
import { UpdateRealtimeModalComponent } from './modal/update-realtime-modal/update-realtime-modal.component';
import { DisconnectedRealtimeModalComponent } from './modal/disconnected-realtime-modal/disconnected-realtime-modal.component';
import { ConfirmSkipInstallRealtimeModalComponent } from './modal/confirm-skip-install-realtime-modal/confirm-skip-install-realtime-modal.component';
import { CycleEndWaitModalComponent } from './modal/cycle-end-wait-modal/cycle-end-wait-modal.component';
import { InstallAndroidModalComponent } from './modal/install-android-modal/install-android-modal.component';
import { VideoVersionVersionModalComponent } from './modal/new-version-video-modal/new-version-video-modal.component';
import { ExerciseFinishedModalComponent } from './modal/exercise-finish-modal/exercise-finish-modal.component';
import { ExerciseFinishedSelfserviceModalComponent } from './modal/exercise-finish-selfservice-modal/exercise-finish-selfservice-modal.component';
import { AchievementComponent } from './achievement/achievement.component';
import { ExerciseDuringPerWeekComponent } from './exercise-during-per-week/exercise-during-per-week.component';
import { CallingModalComponent } from './modal/calling-modal/calling-modal.component';
import { ParadiseGameModalComponent } from './modal/paradise-game-modal/paradise-game-modal.component';
import { DotIndicatorComponent } from './dot-indicator/dot-indicator.component';
import { FillContainerDirective } from '@app/@core/directive/fill-container.directive';
import { ConfirmUseSensorModalComponent } from './modal/confirm-use-sensor-modal/confirm-use-sensor-modal.component';
import { RehubProgressBarComponent } from './progress-bar/progress-bar.component';
import { ConfirmEndExerciseModalComponent } from './modal/confirm-end-exercise-modal/confirm-end-exercise-modal.component';
import { PulseOximeterMeasurementComponent } from './pulseoximetermeasurement/pulseoximetermeasurement.component';
import { MaterialsModalComponent } from './modal/materials-modal/materials-modal.component';
import { SelfServicePillsFormModalComponent } from './modal/self-service-pills-form-modal/self-service-pills-form-modal.component';
import { StartSelfserviceModalComponent } from './modal/start-selfservice-modal/start-selfservice-modal.component';


let components = [
  LoaderComponent,
  LanguageSelectorComponent,
  DropdownComponent,
  ToastComponent,
  CoachComponent,
  CalendarWeekComponent,
  CalendarMonthComponent,
  CalendarDuringComponent,
  BarSelectorComponent,
  FormBallsComponent,
  FormSliderComponent,
  FormCheckboxComponent,
  BarSelectorComponent,
  CheckboxComponent,
  ChatComponent,
  AvatarComponent,
  ExerciseInfoComponent,
  ConfirmModalComponent,
  PulsoWarningModalComponent,
  ConfirmEndExerciseModalComponent,
  InfoModalComponent,
  ConfirmUseSensorModalComponent,
  InstallRealtimeModalComponent,
  UpdateRealtimeModalComponent,
  MaterialsModalComponent,
  SelfServicePillsFormModalComponent,
  DisconnectedRealtimeModalComponent,
  ConfirmSkipInstallRealtimeModalComponent,
  CycleEndWaitModalComponent,
  InstallAndroidModalComponent,
  VideoVersionVersionModalComponent,
  ExerciseFinishedModalComponent,
  ExerciseFinishedSelfserviceModalComponent,
  ExerciseDuringPerWeekComponent,
  CallingModalComponent,
  ParadiseGameModalComponent,
  DotIndicatorComponent,
  StartSelfserviceModalComponent,
  PatientTodayExercisesComponent,
  AchievementComponent,
  RehubProgressBarComponent,
  FillContainerDirective,
  PulseOximeterMeasurementComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    PipeModule,
    FormsModule,
    AvatarModule,
    ReactiveFormsModule
  ],
  declarations: [...components],
  exports: [...components],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
