<div class="modal-overlay" (click)="onOverlayClick($event)">
  <div [ngClass]="{ 'modal-container': !isMobile , 'modal-container-mobile': isMobile }" (click)="$event.stopPropagation()">

    <!-- Pantalla introductoria con Hari -->
    <ng-container *ngIf="showIntro">
      <div class="d-flex flex-column align-items-center justify-content-center text-center color-black" style="height: 81%;">
        <div>
          <img class="hari-image" src="https://web-resources.dycare-services.com/rehub-assets/coach/1.0.0/hari-idle.gif" alt="Hari">
        </div>
        <h2 [ngClass]="{'pl-3 pr-3 text-center mb-4 modal-title-first-mobile': isMobile, 'modal-first-title p-0 mb-4': !isMobile}">
          {{ 'hari_text_title' | translate }}
        </h2>
        <p class="modal-subtitle2 px-4 mb-4" [ngStyle]="{ 'width': isMobile ? '100%' : '60%' }">
          {{ 'hari_text_description' | translate }}
        </p>
        <p class="modal-subtitle2 font-weight-bold">
          {{ 'hari_text_start' | translate }} 💪✨
        </p>
      </div>

      <div [ngClass]="{
        'footer': !isMobile,
        'footer-mobile': isMobile,
        'first-screen-footer': showIntro
      }">
        <div [ngClass]="{ 'btn-box': !isMobile, 'btn-box-mobile': isMobile }">
          <button class="btn btn-secundario" (click)="onClose()">
            {{ 'modal_pills_button_later' | translate }}
          </button>
          <button class="btn btn-continuar" (click)="nextStep()">
            {{ 'modal_pills_button_continue' | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <!-- Flujo normal -->
    <ng-container *ngIf="!showIntro">
      <button *ngIf="isMobile && currentIndexGroup !== 0" class="btn btn-secundario btn-back-mobile" (click)="previousStep()">
        <span>&larr;</span> {{ 'therapy_return_back' | translate }}
      </button>

      <!-- Selección de grupos -->
      <ng-container *ngIf="currentIndexGroup === 0">
        <h2 [ngClass]="{'pl-3 pr-3 text-center mb-2 modal-title-mobile': isMobile, 'modal-title': !isMobile}">
          {{ 'modal_pills_title_categories' | translate }}
        </h2>
        <p class="modal-subtitle" [ngClass]="{'pl-3 pr-3 text-center': isMobile}">
          {{ 'modal_pills_title_categories_group' | translate }}
        </p>
        <div [ngStyle]="{ 'height': isMobile ? '55%' : '65%' }" [ngClass]="{ 'p-3': isMobile }">
          <div class="options">
            <label class="option-card" *ngFor="let goal of allCategories"
              [class.selected]="selected.includes(goal)"
              (click)="toggleSelection(goal)">
              <rehub-icon size="16" class="text-primary" [attr.name]="goal"></rehub-icon>
              <span>{{ "ss_category_group_name_" + goal | translate }}</span>
            </label>
          </div>
        </div>
      </ng-container>

      <!-- Subcategorías -->
      <ng-container *ngIf="currentIndexGroup > 0">
        <h2 [ngClass]="{'pl-3 pr-3 text-center mb-2 modal-title-mobile': isMobile, 'modal-title': !isMobile}">
          {{ 'ss_category_group_name_' + selected[currentIndexGroup - 1] | translate }}
        </h2>
        <p class="modal-subtitle" [ngClass]="{'pl-3 pr-3 text-center': isMobile}">
          {{ 'modal_pills_title_subcategories' | translate }}
        </p>
        <div [ngStyle]="{ 'height': isMobile ? '55%' : '65%' }" [ngClass]="{ 'p-3': isMobile }">
          <div class="options" [ngClass]="{ 'justify-content-center': !isMobile }" [class.remove-justify]="true">
            <label class="option-card" *ngFor="let category of categoriesByGroup"
              [class.selected]="category.selected"
              (click)="toggleSelectionCategory(category)">
              <rehub-icon size="16" class="text-primary" [attr.name]="category.categoryId"></rehub-icon>
              <span>{{ category.categoryName | translate }}</span>
            </label>
          </div>
        </div>
      </ng-container>

      <!-- Footer -->
      <div [ngClass]="{ 'footer': !isMobile, 'footer-mobile': isMobile, 'first-screen-footer': currentIndexGroup === 0}"
           [ngStyle]="(showIntro || (!showIntro && currentIndexGroup === 0))
           ? { 'justify-content': 'flex-end' }
           : { 'justify-content': 'space-between' }">
        <button *ngIf="!isMobile && currentIndexGroup !== 0" class="btn btn-secundario btn-back" (click)="previousStep()">
          <span>&larr;</span> {{ 'therapy_return_back' | translate }}
        </button>

        <div [ngClass]="{ 'btn-box': !isMobile, 'btn-box-mobile': isMobile }">
          <button class="btn btn-secundario" (click)="onClose()">
            {{ 'modal_pills_button_later' | translate }}
          </button>
          <button class="btn btn-continuar"
            (click)="nextStep()"
            [disabled]="currentIndexGroup === 0 && selected.length === 0">
            {{ 'modal_pills_button_continue' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
