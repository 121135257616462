import { API } from "./api";

export type Items = "I18N" | "PROTOCOL" | "JOINT_ANGLES" | "SENSOR_INSTRUCTIONS" | "PROTOCOL_GROUP" | "PROTOCOL_GROUP_PROTOCOL" | "VERSION" | "STAGE" | "COACH_MESSAGE_GROUP" | "COACH_MESSAGE_GROUP_ITEM" | "SS_TREE" |"SS_TREE_NODE";
export type DownloadItems = "I18N_BACKEND" | "I18N_FRONTEND_PROTOCOL" | "I18N_FRONTEND_GENERAL_MULTILANGUAGE" | "I18N_BACKEND_GENERAL_MULTILANGUAGE" | "PROTOCOL" | "EXERCISE_INSTRUCTIONS" | "SENSOR_INSTRUCTIONS" | "JOINT_ANGLES" | "PROTOCOL_GROUP" | "I18N_DASHBOARD_BACKEND_GENERAL_MULTILANGUAGE" | "I18N_DASHBOARD_FRONTEND_GENERAL_MULTILANGUAGE" | "I18N_LOGIN_GENERAL_MULTILANGUAGE";
export type Actions = "CREATE" | "CREATE_FROM_JSON" | "UPDATE" | "LIST" | "GET" | "DELETE" | "DEPLOY" | "LIST_PAGINATION";
export type ExcelFileType = "PENDING_VALIDATION" | "EXERCISE_INSTRUCTIONS"

export class EditorAPI extends API{

  static itemAction(item: Items, action: Actions, data: any): Promise<any>{
    const url = `${this.getBaseUrl()}/editor2/item/${item}/${action}`;
    return this.post(url, data);
  }

  static itemDownload(stage: string = "all", version: string, items: DownloadItems[] = []): Promise<any>{
    const url = `${this.getBaseUrl()}/editor2/item/download`;
    let params: any = {
      items: items,
      stage: stage,
      version: version
    };
    return this.get(url, params);
  }

  static downloadI18nExcel(fileType: ExcelFileType): Promise<any>{
    let url = `${this.getBaseUrl()}/editor2/item/download/i18n`;
    return this.get(url, {fileType: fileType});
  }

  public static getAllProtocols(version: string, categories: any, jointParts: any): Promise<any>{

    let batch: any = [];

    categories.forEach((category: string) => {
      let sortKey = version + "_" + category + "_";
      // Cuando la categoría es logopedia, se cargan todos los protocolos de la categoría logopedia
      if(category == "logopedics"){
        batch.push(EditorAPI.itemAction("PROTOCOL", "LIST", {sortKey: sortKey, ownerId: "REHUB"}))
      // Cuando la categoría es musculoesquelética, se cargan por separado los protocolos de cada parte del cuerpo
      }else if(category == "musculoskeletal"){
        jointParts.forEach((jointPart: string) => {
          batch.push(EditorAPI.itemAction("PROTOCOL", "LIST", {sortKey: sortKey + jointPart, ownerId: "REHUB"}));
        });
      }else if(category == "respiratory"){
        batch.push(EditorAPI.itemAction("PROTOCOL", "LIST", {sortKey: sortKey, ownerId: "REHUB"}));

      }else if(category == "pelvicFloor"){
        batch.push(EditorAPI.itemAction("PROTOCOL", "LIST", {sortKey: sortKey, ownerId: "REHUB"}));

      }else{
        console.error("Category not suported");
      }


    });

    return API.all(batch).then((batchResult: any) => {
       let result: any = {
        protocols: [],
        i18n: {}
       }

       // TODO!: En back se tiene que hacer que solo lleguen los i18n de los protocolos que se devuelven
       batchResult.forEach((resultItem: any)=>{
        result.protocols = [...result.protocols, ...resultItem.protocols];
        result.i18n = {...result.i18n, ...resultItem.i18n};
       });

       return result;
    });
  }

}